/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

* {
    font-family: 'Lato', sans-serif;
}

ion-input {
    border: 1px solid #aaa;
    background: #fff !important;
    border-radius: 5px;
    margin: 15px 0 25px 0;
    --padding-start: 15px !important;
    --padding-end: 15px !important;
    --padding-top: 15px !important;
    --padding-bottom: 15px !important;
}

ion-label {
    --padding-start: 15px !important;
    --padding-end: 15px !important;
    --padding-top: 15px !important;
    --padding-bottom: 15px !important;
}

ion-router-outlet#home .ion-page {
    padding: 56px 0;
}
.plt-ios ion-router-outlet#home .ion-page {
  padding: calc(40px + env(safe-area-inset-top)) 0 56px;
}

ion-footer .buttons {
    display: flex;

    >* {
        flex: 1;
    }
}

app-root ion-app {
    pointer-events: none;
}

ion-alert,
app-root ion-app .ion-page {
    pointer-events: all;
}

.all-caps {
    text-transform: uppercase;
}

ion-card {
    border-radius: 16px;
}


.lead-action a {
    margin-top: 10px;
    border-radius: 50%;
    display: inline-block;
    padding: 7px 8px;
    margin-right: 10px;
    line-height: 0.9;

    ion-icon {
        font-weight: bold;
    }

    &.call {
        background-color: #df2444;
    }

    &.wa {
        background-color: #25D366;
    }

    &.fup {
        background-color: #f1a913;
    }

    &.mail {
        background-color: #258ad9;
    }

    &.detail {
        background-color: #f55a00;
    }
}
.ion-text-right {
  float:right
}

.validation-msg {
    display: none;
}

.ion-invalid .validation-msg {
    display: block;
}